import { graphql } from 'gatsby'
import { EditorProvider } from '@tiptap/react'
import { Header, Footer } from '@/components'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import { Box } from '@mui/material'
import React from 'react'
import { PrivacyNoticeDetailDrawer } from '@/components/PrivacyNoticeDetailDrawer'

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
      },
      textAlign: {
        default: null,
        parseHTML: (element) => element.getAttribute('text-align'),
        renderHTML: (attributes) => {
          return {
            'text-align': attributes.textAlign,
            style: `text-align: ${attributes.textAlign}`,
          }
        },
      },
    }
  },
})

const extensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Link.configure({
    HTMLAttributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  }),
  Table,
  TableRow,
  TableHeader,
  CustomTableCell,
]

const PrivacyPolicyBrazil = (props) => {
  const footerLocale = JSON.parse(props.data.footerLocale.data)

  const { detailDrawerOpen, showDetailDrawer, detailDrawerContent } = PrivacyNoticeDetailDrawer({
    pageLocale: footerLocale,
  })
  const content = (
    <Box
      sx={{
        margin: '0px 24px 24px',
        paddingTop: '120px',
        'h2,h3,h4': {
          fontWeight: '700',
        },
      }}
    >
      <h2 style={{ textAlign: 'center' }}>Privacy Policy of iMile UK</h2>
      <p>
        With this privacy policy (hereinafter as “Policy”) we would like to inform you about how we
        process personal data during our business cooperation with you, your use of our services and
        your visit to our website <a href='https://www.imile.com/'>https://www.imile.com/</a> The
        protection of your privacy is of the utmost importance to us, so compliance with the legal
        provisions on data protection is a matter of course for us.
      </p>
      <p>This Policy describes:</p>
      <ul>
        <li>Name and Contact Details of the Controller</li>
        <li>Data Protection Officer</li>
        <li>Personal Data</li>
        <li>Use of Cookies</li>
        <li>Purpose of Processing</li>
        <li>Legal Basis</li>
        <li>Legitimate Interests</li>
        <li>Requirement or Obligation to Provide Data</li>
        <li>Data Sources</li>
        <li>Storage Period</li>
        <li>Data Recipient</li>
        <li>Transfer to Third Countries</li>
        <li>Your Rights</li>
        <li>Automated Decision-Making Including Profiling</li>
        <li>Status of the Privacy Policy</li>
      </ul>
      <h3>1. Name and Contact Details of the Controller</h3>
      <p>iMile Logistics Co., Ltd.</p>
      <p>Company Number: 15652374</p>
      <p>Registered Address: C/O MISHCON DE REYA AFRICA HOUSE </p>
      <p>70 KINGSWAY</p>
      <p>LONDON</p>
      <p>GREATER LONDON</p>
      <p>UNITED KINGDOM WC2B 6AH</p>
      <h3>2. Data Protection Officer</h3>
      <p>
        Should you have any questions about our data protection measures, the processing of your
        data or the protection of your rights as a data subject, you can contact us and our data
        protection officer as follows:
      </p>
      <p>Privacy Protection Office</p>
      <p>iMile Italy S.r.l.</p>
      <p>Via Giosuè Carducci, 15</p>
      <p>20123 Milano</p>
      <p>Italy</p>
      <p>
        If you have any questions or concerns regarding your information, please contact{' '}
        <a href='mailto:dpobrazil@imile.me'>DPO@imile.me</a>
      </p>
      <h3>3. Personal Data</h3>
      <p>
        Personal data is any information relating to an identified or identifiable individual. This
        includes the following categories of personal data that we process:
      </p>
      <ul>
        <li>
          Your contact information (such as first and last name, address, email address, phone
          number),
        </li>
        <li>Your correspondence with us,</li>
        <li>Log files containing information about your visit to our website,</li>
        <li>Online identifiers (such as cookie IDs, IP addresses, advertising IDs),</li>
        <li>
          Customer data (such as billing data, user profiles, address, order history, payment data)
        </li>
      </ul>
      <h3>4. Use of Cookies</h3>
      <p>General information about cookies</p>
      <p>
        Cookies are data records that are stored in databases of the browser. For example, user
        identification numbers are stored here, which are transmitted to the user’s computer when
        the website is used and managed there. The data records are kept ready there for later
        access. Typical uses of cookies are, for example, language selection, consent documentation
        or authentication of the user.
      </p>
      <p>Session cookies</p>
      <p>
        Session cookies are stored for the duration of a website visit and then automatically
        deleted when the browser is closed. They ensure, for example, that video and audio files can
        be played, your user input is temporarily stored during the input time and thus the user
        experience is improved.
      </p>
      <p>Persistent cookies</p>
      <p>
        Persistent cookies remain on your terminal device even after you close the browser. These
        cookies can, for example, store your user preferences, such as language settings, and
        analyze user behavior on our website. The storage period of persistent cookies is determined
        individually per cookie. After expiry of the period, they are automatically deleted.
      </p>
      <p>
        Information on the individual cookies including the function durations can be found within
        the cookie banner.
      </p>
      <p>Withdrawal of consent for cookies</p>
      <p>
        The modification or withdrawal of consent for the placement of cookies can be made using the
        following link: <a onClick={showDetailDrawer}>Consent Settings</a>
      </p>
      <h3>5. Purpose of Processing</h3>
      <p>We process your data for the following purposes:</p>
      <ul>
        <li>In order to provide our service,</li>
        <li>For corresponding with you,</li>
        <li>For processing contracts with you,</li>
        <li>On quality assurance and statistics,</li>
        <li>For your participation in our surveys,</li>
        <li>In order to improve our service</li>
      </ul>
      <h3>6. Legal Basis</h3>
      <p>We base the processing of your data on the following legal basis:</p>
      <ul>
        <li>Your consent, if you have given us such consent,</li>
        <li>The initiation or execution of a contract with you,</li>
        <li>The fulfilment of legal obligations</li>
        <li>The implementation of our legitimate interests</li>
      </ul>
      <h3>7. Legitimate Interests</h3>
      <p>When processing your data, we pursue the following legitimate interests:</p>
      <ul>
        <li>The improvement of our offer,</li>
        <li>The protection of our systems against misuse,</li>
        <li>The production of statistics,</li>
        <li>The storage of our correspondence with you.</li>
      </ul>
      <h3>8. Requirement or Obligation to Provide Data</h3>
      <p>
        Unless this is expressly stated, the provision of your data is not required or obligatory.
      </p>
      <h3>9. Data Sources</h3>
      <p>
        If we do not receive the data from you, or from the devices you use, we receive it from the
        following sources:
      </p>
      <ul>
        <li>Data on B2B contacts of specialised service providers</li>
      </ul>
      <h3>10. Storage Period</h3>
      <p>We store your data,</p>
      <ul>
        <li>if you have consented to the processing, at most until you revoke your consent;</li>
        <li>
          if we need the data for the execution of a contract, at most for as long as the
          contractual relationship with you exists;
        </li>
        <li>
          if we use the data on the basis of a legitimate interest, at most for as long as your
          interest in deletion or anonymisation does not outweigh the data;
        </li>
        <li>
          insofar as statutory storage obligations exist, until the end of the storage periods.
        </li>
      </ul>
      <h3>11. Data Recipient</h3>
      <p>
        In processing your data, we work with the following service providers who have access to
        your data:
      </p>
      <ul>
        <li>Web analytics tools provider</li>
        <li>Logistics service provider</li>
        <li>Call Center</li>
        <li>Email and newsletter providers</li>
        <li>Cloud services</li>
        <li>Service provider for accounting and invoicing</li>
        <li>Sorting software service provider</li>
      </ul>
      <h3>12. Transfer to Third Countries</h3>
      <p>
        Data is being transferred to countries outside the European Economic Area. We only transfer
        personal data to third countries where the EU Commission has confirmed an adequate level of
        protection or where we can ensure the careful handling of personal data by means of
        contractual agreements or other suitable guarantees, such as certifications or proven
        compliance with international security standards.
      </p>
      <h3>13. Your Rights</h3>
      <p>As a data subject, you have the following rights:</p>
      <ul>
        <li>
          To request information about the processing of your data, as well as to receive a copy of
          your personal data. Among other things you may request information on the purposes of the
          processing, the categories of personal data processed, the recipients of the data (if a
          transfer is made), the duration of the storage or the criteria for determining the
          duration;
        </li>
        <li>
          To receive personal data relating to you in a structured, common and machine-readable
          format or to transfer it to another person in charge;
        </li>
        <li>
          To correct your data. If your personal data is incomplete, you have the right to complete
          the data, taking into account the purposes of the processing;
        </li>
        <li>To have your data deleted or blocked;</li>
        <li>To have the processing restricted;</li>
        <li>To object to the processing of your data;</li>
        <li>To revoke your consent to the processing of your data for the future and</li>
        <li>
          To complain to the responsible supervisory authority about unauthorised data processing.
        </li>
      </ul>
      <h3>14. Automated Decision Making Including Profiling</h3>
      <p>We do not perform automated decision-making or profiling.</p>
      <h3>15. Status of the Privacy Policy</h3>
      <p>If our processes change, we adjust the information. </p>
      <p>Status of this privacy policy: last updated on 08/16/2024. </p>
    </Box>
  )
  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      {content}
      {detailDrawerOpen && detailDrawerContent}
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default PrivacyPolicyBrazil

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["PrivacyPolicyBrazil"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
